import { HoppModule } from "@hoppscotch/common/modules"
import axios from "axios"
import { z } from "zod"
import * as E from "fp-ts/Either"
import { def } from "./../platform/auth/auth.platform"
import type { Router } from "vue-router"

const SiteProtectionExpectedResultSchema = z.object({
  name: z.literal("SITE_PROTECTION"),
  value: z.enum(["ENABLE", "DISABLE"]),
})

const getSiteProtectionStatus = async () => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_BACKEND_API_URL}/site/status`,
      {
        withCredentials: true,
      }
    )

    const responseData = res.data

    const result = SiteProtectionExpectedResultSchema.safeParse(responseData)

    if (!result.success) {
      return E.left("SOMETHING_WENT_WRONG")
    }

    return E.right(result.data)
  } catch (err) {
    console.error(err)
    return E.left("SOMETHING_WENT_WRONG")
  }
}

const whoIsAccessing = async () => {
  const probableUser = def.getProbableUser()

  if (probableUser) {
    await def.waitProbableLoginToConfirm()
    const confirmedUser = def.getCurrentUser()

    if (confirmedUser) return "AUTHENTICATED"
  }

  return "GUEST"
}

export const SiteProtectionModule: HoppModule = {
  onRouterInit(_, router) {
    def.getAuthEventsStream().subscribe(async (event) => {
      // if user logs out, check if site protection is enabled, if yes, redirect to login-required page
      if (event.event === "logout") {
        const siteProtectionStatus = await getSiteProtectionStatus()
        handleSiteProtectionResponse(siteProtectionStatus, router)
      }
    })
  },

  async onBeforeRouteChange(to, _, router) {
    const who = await whoIsAccessing()

    if (to.meta.onlyGuest && who === "GUEST") return
    if (!to.meta.onlyGuest && who === "AUTHENTICATED") return

    if (to.meta.onlyGuest && who === "AUTHENTICATED") {
      router.replace("/")
      return
    }

    const siteProtectionStatus = await getSiteProtectionStatus()

    handleSiteProtectionResponse(siteProtectionStatus, router)
  },
}

const handleSiteProtectionResponse = (
  siteProtectionStatus: E.Either<
    string,
    z.infer<typeof SiteProtectionExpectedResultSchema>
  >,
  router: Router
) => {
  if (E.isLeft(siteProtectionStatus)) {
    // redirect to login-required page with error message
    return router.push({
      path: `/login-required`,
      query: {
        error: "site_protection.error_fetching_site_protection_status",
      },
    })
  }

  const siteProtectionValue = siteProtectionStatus.right.value

  if (siteProtectionValue === "ENABLE") {
    return router.push("/login-required")
  }

  return
}
