import axios from "axios"
import { differenceInDays, parseISO } from "date-fns"
import { Service } from "dioc"
import { getI18n } from "@hoppscotch/common/modules/i18n"
import {
  BannerContent,
  BannerService,
  BannerType,
  BANNER_PRIORITY_MEDIUM,
} from "@hoppscotch/common/services/banner.service"

const daysRemaining = (expiryDate: string) =>
  differenceInDays(parseISO(expiryDate), new Date())

/**
 * Returns the banner type based on the number of days remaining for the license to expire
 */
const getLicenseBannerType = (daysRemaining: number): BannerType => {
  if (daysRemaining <= 0) {
    return "error"
  }
  if (daysRemaining < 4) {
    return "warning"
  }
  return "info"
}

/**
 * Returns a banner to be displayed based on the license status
 */
const getLicenseBanner = (data: any): BannerContent => {
  const islicenseValid = data.status === "valid"
  return {
    type: getLicenseBannerType(
      islicenseValid ? daysRemaining(data.validUntilISO) : 0
    ),
    text: (t: ReturnType<typeof getI18n>) => {
      if (islicenseValid) {
        return t("license.expiring", {
          days: daysRemaining(data.validUntilISO),
        })
      }
      return t("license.expired")
    },
    score: BANNER_PRIORITY_MEDIUM,
  }
}

/**
 * Service for managing licensing information.
 */
export class LicensingService extends Service {
  /**
   * The ID of the licensing service.
   */
  public static readonly ID = "LICENSING_SERVICE"

  private readonly banner = this.bind(BannerService)

  constructor() {
    super()
    this.getLicenseStatus()
  }

  /**
   * Retrieves the license status from the backend and displays a banner if the license is about to expire
   * or is expired
   */
  public async getLicenseStatus() {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_BACKEND_API_URL}/license/status`,
        {
          withCredentials: true,
        }
      )

      if (
        (res.data.status === "valid" &&
          daysRemaining(res.data.validUntilISO) < 11) ||
        res.data.status === "valid_but_past_due"
      ) {
        this.banner.showBanner(getLicenseBanner(res.data))
      }
    } catch (error) {
      console.error(error)
    }
  }
}
